// extracted by mini-css-extract-plugin
export var review_button = "reviews-module--review_button--128d3";
export var review_item = "reviews-module--review_item--90917";
export var review_item_client_name = "reviews-module--review_item_client_name--618fb";
export var review_item_link = "reviews-module--review_item_link--4da47";
export var review_item_object = "reviews-module--review_item_object--8b979";
export var review_left = "reviews-module--review_left--5d0ae";
export var review_right = "reviews-module--review_right--ff3e4";
export var review_right_images = "reviews-module--review_right_images--a64e3";
export var review_right_images_block = "reviews-module--review_right_images_block--754a7";
export var review_right_images_img = "reviews-module--review_right_images_img--26c7f";
export var review_right_images_link = "reviews-module--review_right_images_link--dd694";
export var review_right_images_photo = "reviews-module--review_right_images_photo--5c18d";
export var review_right_images_photo_img = "reviews-module--review_right_images_photo_img--017c0";
export var review_right_images_photo_img_mob = "reviews-module--review_right_images_photo_img_mob--fbbee";
export var review_right_images_photo_mob = "reviews-module--review_right_images_photo_mob--b048f";
export var review_right_with_review = "reviews-module--review_right_with_review--75d29";
export var review_title = "reviews-module--review_title--6deae";
export var reviews_items = "reviews-module--reviews_items--7f796";